$size-0: 0px;
$size-1: 1px;
$size-2: 2px;
$size-2: 2px;
$size-2p: 2.5px;
$size-3: 3px;
$size-4: 4px;
$size-6: 6px;
$size-8: 8px;
$size-9: 9px;
$size-10: 10px;
$size-12: 12px;
$size-14: 14px;
$size-15: 15px;
$size-16: 16px;
$size-18: 18px;
$size-20: 20px;
$size-22: 22px;
$size-24: 24px;
$size-26: 26px;
$size-28: 28px;
$size-30: 30px;
$size-32: 32px;
$size-34: 34px;
$size-60: 60px;
$size-74: 74px;
$size-100: 100px;
$size-110: 110px;
$size-146: 146px;
$size-150: 150px;
$size-200: 200px;
$size-250: 250px;
$size-300: 300px;
$size-350: 350px;
$size-400: 400px;
$size-500: 500px;
$size-600: 600px;
$size-700: 700px;
$size-half: 50%;
$size-full: 100%;
$size-full-vh: 100vh;
$size-full-vw: 100vw;
