@use '../../../../themes/sizes' as *;
@use '../../../../themes/mixins' as *;
@use '../../../../themes/colors' as *;

.task-filter {
  @include flex-center-vert;
  margin-top: $size-16;
  background-color: $color-white;
  padding: $size-16;
  border-radius: $size-6;

  .field {
    & > .input__main,
    & > .select__main {
      width: $size-250;
    }

    & + .field {
      margin-left: $size-20;
    }
  }
}
