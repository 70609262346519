@use '../../../../themes/sizes' as *;
@use '../../../../themes/font-weights' as *;
@use '../../../../themes/mixins' as *;
@use '../../../../themes/colors' as *;

.task-group-filter {
  @include flex-center-vert;
  margin-top: $size-16;
  background-color: $color-white;
  padding: $size-16;
  border-radius: $size-6;

  .field {
    & > .input__main {
      width: $size-300;
    }
  }
}
