@use '../../themes/sizes' as *;
@use '../../themes/font-weights' as *;
@use '../../themes/mixins' as *;
@use '../../themes/colors' as *;

.modal {
  .ant-modal-header {
    margin-bottom: 0;
    padding: $size-16;
  }

  &__title {
    @include font-size($size-16, $font-weight-500, $size-24);
    text-align: center;
  }

  .ant-modal-body {
    padding: $size-16 $size-20;
    border-top: $size-1 solid $color-soft-peach;
    border-bottom: $size-1 solid $color-soft-peach;
  }

  .ant-modal-footer {
    margin-top: 0;
    padding: $size-12 $size-20;
  }
}
