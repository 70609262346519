@use '../../themes/font-weights' as *;
@use '../../themes/colors' as *;
@use '../../themes/sizes' as *;
@use '../../themes/mixins' as *;

.text {
  @include font-size($size-14, $font-weight-400, $size-20);
  font-family: 'Be Vietnam Pro';
  color: $color-dune;
}
