@use '../../themes/sizes' as *;

.custom-tree-select {
  .select-label {
    &__required {
    }
  }

  &__input {
    width: $size-full;
  }

  &__popup {
    max-height: $size-300;
    overflow: auto;

    .switcher-icon {
    }
  }
}
