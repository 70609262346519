@use '../../../../themes/sizes' as *;
@use '../../../../themes/mixins' as *;
@use '../../../../themes/colors' as *;
@use '../../../../themes/font-weights' as *;

.employee {
  @include flex-column;
  height: $size-full;

  &__table {
    @include flex-1;
    margin-top: $size-16;

    .icons {
      @include flex-center-vert;

      &__delete {
        cursor: pointer;
      }
    }
  }
}
