@use '../../themes/mixins' as *;
@use '../../themes/sizes' as *;
@use '../../themes/colors' as *;

.pagination {
  @include flex-center-vert;
  padding: $size-12 $size-16;
  background-color: $color-white;
  border-top: $size-2 solid $color-cultured;
  justify-content: space-between;

  &__size,
  &__total {
    color: $color-smokey-grey;

    &--hide {
      visibility: hidden;
    }
  }

  &__size {
    @include flex-center-vert;

    &-select {
      margin-left: $size-8;
    }
  }

  &__pages {
    .ant-pagination-item {
      border: $size-1 solid $color-rose-madder;

      &:not(.ant-pagination-item-active) {
        border: $size-1 solid $color-soft-peach;
      }
    }

    .ant-pagination-item-ellipsis {
      top: $size-6 !important;
    }
  }
}
