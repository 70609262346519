@use '../../../../themes/sizes' as *;
@use '../../../../themes/font-weights' as *;
@use '../../../../themes/mixins' as *;

.preview {
  min-height: $size-600;

  &__name,
  &__description {
    @include flex-1;
  }

  &__description {
    height: $size-60;
  }

  &-table__title {
    margin-top: $size-16;
    font-weight: $font-weight-700;
  }

  &__table {
    @include flex-1;
    margin-top: $size-16;

    .task-name {
      display: inline-flex;
    }
  }
}
