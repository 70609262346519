@use '../../themes/font-weights' as *;
@use '../../themes/sizes' as *;
@use '../../themes/mixins' as *;
@use '../../themes/colors' as *;

.group-permission {
  @include flex-column;
  height: $size-full;

  &__table {
    @include flex-1;
    margin-top: $size-16;

    &-employee {
      cursor: pointer;
    }

    .icons {
      @include flex-center-vert;

      &__edit,
      &__delete {
        cursor: pointer;
        margin-left: $size-8;
      }

      &__edit {
        margin-left: $size-0;
      }
    }
  }
}
