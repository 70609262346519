$color-white: #ffffff;
$color-black: #000000;
$color-cultured: #f4f4f5;
$color-dark-grey-blue: #274056;
$color-quill-grey: gray;
$color-blue-jay: #385975;
$color-rhino: #292e6f;
$color-tropical-blue: #c8dff3;
$color-slate: #495d6f;
$color-dune: #333;
$color-light-red: #ff3141;
$color-water: #e6f4ff;
$color-white-smoke: #f5f5f5;
$color-flush-mahogany: #ce373d;
$color-smokey-grey: #737373;
$color-vampire-grey: #525252;
$color-soft-peach: #eee;
$color-rose-madder: #db1b33;
$color-misty-rose: #ffe4e6;
$color-dawn: #a3a3a3;
$color-primary: #2560e5;
