@use '../../../../themes/sizes' as *;
@use '../../../../themes/colors' as *;
@use '../../../../themes/font-weights' as *;
@use '../../../../themes/mixins' as *;

.fields {
  margin-top: $size-16;
  background-color: $color-white;
  border-radius: $size-8;

  .ant-form-item {
    @include flex-1;
  }

  &__title {
    padding: $size-16 $size-24;
    font-weight: $font-weight-700;
    border-bottom: $size-1 solid $color-soft-peach;
  }

  &__content {
    padding: $size-16 $size-24;
  }
}
