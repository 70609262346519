@use '../../../../themes/mixins' as *;
@use '../../../../themes/sizes' as *;
@use '../../../../themes/colors' as *;

.template-filter {
  @include flex-center-vert;
  margin-top: $size-16;
  background-color: $color-white;
  padding: $size-16;
  border-radius: $size-6;

  .filter-action {
    &__input {
      margin-right: $size-30;

      &--disabled {
        .ant-select-selector {
          background-color: $color-primary;
          border-color: $color-primary;

          .ant-select-selection-item {
            color: $color-white;
          }
        }

        .ant-select-arrow {
          color: $color-white;
        }
      }
    }
  }

  .field {
    & > .input__main,
    & > .select__main {
      width: $size-250;
    }

    & + .field {
      margin-left: $size-20;
    }
  }
}
