@use '../../../../themes/sizes' as *;
@use '../../../../themes/mixins' as *;
@use '../../../../themes/colors' as *;
@use '../../../../themes/font-weights' as *;

.permissions {
  .permission-group {
    background-color: $color-white;
    border-radius: $size-6;
    margin-top: $size-16;
    padding: $size-16;

    &__label {
      font-weight: $font-weight-700;
      margin-left: $size-4;
      margin-right: $size-8;
    }

    &__value {
      width: $size-400;
    }
  }

  &__table {
    margin-top: $size-16;
  }
}
