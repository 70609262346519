@use '../../themes/colors' as *;
@use '../../themes/sizes' as *;
@use '../../themes/mixins' as *;

.tree-menu {
  .menu-title {
    @include flex-center-vert;
    cursor: pointer;
    margin-bottom: $size-4;

    &__icon {
    }

    &__text {
      @include flex-1;
      margin-left: $size-2;
      padding-left: $size-4;
      line-height: $size-24;
      border-radius: $size-6;

      &:hover {
        background-color: $color-white-smoke;
      }

      &--active,
      &:active {
        background-color: $color-water;
      }
    }
  }

  &__children {
    padding-left: $size-24;
  }
}
