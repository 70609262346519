@use '../../themes/font-weights' as *;
@use '../../themes/sizes' as *;
@use '../../themes/colors' as *;
@use '../../themes/mixins' as *;

.profile {
  &__title {
    @include font-size($size-22, $font-weight-700, $size-28);
  }

  .info {
    display: flex;
    gap: $size-32;
    margin-top: $size-24;
    border-radius: $size-6;

    .brief,
    .details {
      background-color: $color-white;
      border-radius: $size-6;
    }

    .brief {
      @include flex-1;
      padding: $size-16;

      .header {
        display: block;
        background-color: $color-rose-madder;
        border-radius: $size-8;
        padding: $size-24 0;
        text-align: center;
        background-image: url('../../assets/images/profile-flower.png');
        background-size: contain;
        background-repeat: no-repeat;

        &__avatar {
          width: $size-74;
          height: $size-74;
          border-radius: $size-half;
          border: $size-3 solid $color-white;
        }

        &__contact {
          &-name,
          &-email {
            color: $color-white;
          }

          &-name {
            @include font-size($size-16, $font-weight-500, $size-24);
          }
        }
      }

      .content {
        margin-top: $size-16;
        padding: $size-16 0 0 0;
        border-top: $size-1 solid $color-misty-rose;

        &__item {
          &-label {
            font-weight: $font-weight-500;
            color: $color-dawn;
          }

          &-value {
            @include font-size($size-16, $font-weight-400, $size-24);
          }
        }
      }
    }

    .details {
      flex: 3;
      padding: $size-24;

      &__phone,
      &__birthday,
      &__telegram,
      &__zalo {
        @include flex-1;
      }
    }
  }
}
