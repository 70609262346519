@use '../../themes/sizes' as *;
@use '../../themes/colors' as *;

.tree-select {
  .select-label {
    margin-bottom: $size-4;

    &__required {
      display: inline-block;
      margin-left: $size-4;
      color: $color-light-red;
      font-size: $size-12;
    }
  }

  &__input {
    width: $size-full;
  }

  &__popup {
    max-height: $size-300;
    overflow: auto;

    .ant-select-tree-switcher:has(> .switcher-icon) {
      pointer-events: none;
    }
  }
}
